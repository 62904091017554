import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { GroupByPipe } from '@z-trippete/angular-pipes';

import { StatsStoreEffects } from './effects';
import { StatsReducer, statsReducerFactory } from './reducer';
import { State } from './state';

export const STATS_REDUCER_TOKEN = new InjectionToken<
  ActionReducer<Map<string, State>>
>('Stats state reducer');

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('stats', STATS_REDUCER_TOKEN),
    EffectsModule.forFeature([StatsStoreEffects]),
  ],
  providers: [
    StatsReducer,
    {
      provide: STATS_REDUCER_TOKEN,
      deps: [StatsReducer],
      useFactory: statsReducerFactory,
    },
    GroupByPipe,
  ],
})
export class StatsStoreModule {}
