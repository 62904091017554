import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize, difference, get, omit } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { StatsFiltersResponse, StatsResponse } from '../../models';
import { StatsHelperService, StatsService } from '../../services';
import { ExportService } from '../../services/export.service';
import { NotificationService } from '../../ui/services/notification.service';
import { RootState } from '../root-state';
import { UserPreferencesStoreActions } from '../user-preferences-store';

import { StatsStoreActions } from '.';
import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

const COLUMNS_REMOVED = [
  'rooms_checkout',
  'rooms_checkin',
  'in_stay_rooms',
  'presence_rooms',
];
@Injectable()
export class StatsStoreEffects {
  constructor(
    private dataService: StatsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    public store: Store<RootState>,
    public statsHelperService: StatsHelperService,
  ) {}

  setSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSettingsRequest),
      mergeMap((action) => {
        return [
          new UserPreferencesStoreActions.UpdateRequestAction({
            preferences: {
              options: {
                statsRevenue: {
                  settings: action.settings,
                },
              },
            },
          }),
          fromActions.setSettingsSuccess({
            settings: action.settings,
          }),
        ];
      }),
    ),
  );

  initSelectedColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSelectedColumnsRequest),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { tableKey } = action;
        const selectedColumns: string[] = get(
          store,
          ['userPreferences', 'data', 'tablecolumnView', tableKey],
          [],
        );
        const settings = get(
          store,
          ['userPreferences', 'data', 'tablecolumnView', 'statsProducion'],
          [],
        );
        return [
          fromActions.setSelectedColumnsSuccess({
            selectedColumns: selectedColumns.filter(
              (col) => !COLUMNS_REMOVED.includes(col),
            ),
            settings,
          }),
        ];
      }),
    ),
  );

  loadAccommodationTableauNUmbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadAccommodationTableauNumberRequest),
      mergeMap(({ data, tableauNumbersToLoad }) => {
        return this.dataService.loadAccommodationTableauNumber(data).pipe(
          map((response: IResponseSuccess<Partial<StatsResponse>>[]) => {
            const tableauNumbers = response.map((resp) => resp.data);
            return fromActions.LoadAccommodationTableauNumberSuccess({
              tableauNumbers,
              tableauNumbersToLoad,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.LoadAccommodationTableauNumberFailure(error));
          }),
        );
      }),
    ),
  );

  changePropertiesTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changePropertiesTab),
      concatMap(({ filtersHeaderValues, propertiesSelectedIds }) => {
        return [
          fromActions.setPropertiesSelected({ propertiesSelectedIds }),
          fromActions.buildFiltersOptions({
            filtersHeaderValues,
            setInHeaderFiltersValue: true,
          }),
          fromActions.load(),
        ];
      }),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(fromActions.loadFiltersFailure(error));
      }),
    ),
  );

  setMinDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMinDateRequest),
      mergeMap(({ propertyIds }) => {
        return this.dataService.loadMinDate(propertyIds).pipe(
          concatMap(
            ({
              data: { min_date },
            }: IResponseSuccess<{ min_date: string }>) => {
              return [
                fromActions.loadMinDateSuccess({ min_date }),
                fromActions.buildComparePeriodOptions({ min_date }),
              ];
            },
          ),
        );
      }),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(fromActions.loadFiltersFailure(error));
      }),
    ),
  );

  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFiltersRequest),
      switchMap((data) => {
        const { propertyIds } = data;
        return this.dataService.loadFilters(propertyIds).pipe(
          concatMap((response: StatsFiltersResponse) => {
            return [
              fromActions.loadFiltersSuccess({
                filters: response,
              }),
              fromActions.buildFiltersOptions({
                filtersHeaderValues: null,
                setInHeaderFiltersValue: false,
              }),
              fromActions.setEnableValueChangeFilters(),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFiltersFailure(error));
          }),
        );
      }),
    ),
  );

  splitLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSplittedRequest),
      switchMap((data) => {
        this.store.dispatch(StatsStoreActions.clearChart());

        this.store.dispatch(StatsStoreActions.clearAllRows());

        this.store.dispatch(StatsStoreActions.clearTotals());

        const { data: dataTosend, translations, hooks } = data;

        return this.dataService
          .splitRequest(
            dataTosend,
            (data: IResponseSuccess<StatsResponse>[]) => {
              if (!data?.length) {
                return;
              }

              const lastResponse = data[data.length - 1];

              const { warnings } = lastResponse.meta;

              if (warnings?.length) {
                const previousWarning = data.reduce((acc, curr) => {
                  acc = [...acc, ...(curr.meta?.warnings || [])];

                  return acc;
                }, []);

                const newWarnings = difference(previousWarning, warnings);
                if (newWarnings.length) {
                  newWarnings.forEach((warning) => {
                    this.notifications.push({
                      title: capitalize(this.translate.instant('attenction')),
                      content: warning,
                      type: 'warning',
                    });
                  });
                }
              }

              /**@description rimuovo i totali el'incidenza %  perché verranno valorizzati da una chiamata apposita */

              const stats = {
                ...omit(lastResponse.data, 'totals'),
              } as Partial<StatsResponse>;

              this.store.dispatch(
                fromActions.addSplittedResponse({
                  stats,
                  translations,
                  reservation_date_from:
                    lastResponse.data.reservation_date_from,
                  reservation_date_to: lastResponse.data.reservation_date_to,
                }),
              );
              this.store.dispatch(
                fromActions.buildChart({
                  translations,
                }),
              );
            },
            translations,
          )
          .pipe(
            effectHooks(hooks),
            switchMap(() => {
              return [
                fromActions.loadSplittedSuccess(),
                fromActions.buildChart({ translations }),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          );
      }),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap((data) => {
        const { data: dataTosend, translations, hooks } = data;
        return this.dataService.load(dataTosend).pipe(
          effectHooks(hooks),
          concatMap((response: IResponseSuccess<StatsResponse>) => {
            const { warnings } = response.meta;
            if (warnings?.length) {
              warnings.forEach((warning) => {
                this.notifications.push({
                  title: capitalize(this.translate.instant('attenction')),
                  content: warning,
                  type: 'warning',
                });
              });
            }
            return [
              fromActions.loadSuccess({
                stats: response.data,
                translations,
              }),
              fromActions.buildChart({ translations }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportRequest),
      switchMap(({ exportData, data }) =>
        this.dataService.export(exportData, data).pipe(
          map((response: any) => {
            return fromActions.exportSuccess({
              exportId: response.data.export_ids[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFailure(error));
          }),
        ),
      ),
    ),
  );
}
